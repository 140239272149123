import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import sketch from '../assets/images/church-sketch-small-no-bg.png';

import {responsiveTitle1} from '../components/typography.module.css';

export default function DonatePage() {
  return (
    <Layout>
      <SEO title="Donate" />
      <Container>
        <h1 className={responsiveTitle1}>Donate</h1>
        <img src={sketch} alt="Sketch of St. Demetrius Church" />
        <div>
          <p>
            <em>Please do not send money directly to the Church.</em>
          </p>
          <h2>Donate Online</h2>
          <p>
            You can submit a one-time or recurring donation here on the website.
            Please ensure to specify the reason for your donation (i.e.,
            Candles, Stewardship, Litany, Church Slava, Christmas, Easter, etc.)
          </p>
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top">
            <input
              type="hidden"
              name="hosted_button_id"
              value="6AYN2HCXNVFUG"
            />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/en_US/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>

          <h2>Donate via Personal Check or Money Order</h2>
          <p>
            To donate to St. Demetrius Serbian Orthodox Church, please send your
            personal check or money order to:
          </p>
          <b>
            <em>Please do not send cash.</em>
          </b>
          <p>
            PO Box 151
            <br />
            Akron, OH 44309
          </p>
          <p>
            <em>Thank You for Your Support!</em>
          </p>
        </div>
      </Container>
    </Layout>
  );
}
